import { client } from "@/lib/aspida/client";
import { LiveScheduleListResponse } from "@/lib/aspida/api/@types";
import { RequestOption, useApiRequest } from "@/hooks/api/useApiRequest";

/**
 * ライブ情報取得API hook
 * @returns ライブ情報APIモジュール
 */
export const useFetchLivesApi = () => {
    const { request, apiRequestState } =
        useApiRequest<LiveScheduleListResponse>()

    return {
        fetchLives: async (
            option?: RequestOption<LiveScheduleListResponse>
        ) => {
            await request(async () => {
                return await client.v1.lives.schedules.get()
            }, option)
        },
        fetchLivesApiState: apiRequestState,
    }
}
