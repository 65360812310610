import React, { FC, useCallback, useEffect, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import {
    LiveBookingRequest,
    LiveScheduleResponse,
} from "@/lib/aspida/api/@types"
import BtnKakuLoadAnime from "@/components/btn/BtnKakuLoadAnime"
import { Head } from "@/components/Head"
import { kaigyoToBr } from "@/functions/kaigyoToBr"
import messagesParam from "@/params/messagesParam"
import { GlobalLayout } from "@/components/GlobalLayout"
import {
    CustomInput,
    CustomSelect,
    LiveSelectionForm,
    UnReservableForm,
} from "@/components/pages/LiveBooking/LiveBooking.component"
import { navigate } from "gatsby"
import { StringParam, useQueryParam } from "use-query-params"
import { useFetchLivesApi } from "@/hooks/api/useFetchLivesApi"
import { useHealthCheckApi } from "@/hooks/api/useHealthCheckApi"
import { useLiveBookingApi } from "@/hooks/api/useLiveBookingApi"
import { createLiveBookingUrl } from "@/components/pages/LiveBooking/url"

export const LiveBooking: FC = () => {
    const { fetchLives, fetchLivesApiState } = useFetchLivesApi()
    const { healthCheck, healthCheckApiState } = useHealthCheckApi()
    const { submit, liveBookingApiState } = useLiveBookingApi()
    const [queriedTargetSlug] = useQueryParam("slug", StringParam)
    const [reservableLiveList, setReservableLiveList] = useState<
        LiveScheduleResponse[]
    >([])
    const [isReservable, setIsReservable] = useState<boolean>(true)
    const [currentLive, setCurrentLive] = useState<LiveScheduleResponse>()
    const [isDisabled, setIsDisabled] = useState<boolean>(false)

    const {
        handleSubmit,
        formState: { errors, isValid },
        setValue,
        control,
        watch,
    } = useForm<LiveBookingRequest>({
        mode: "onBlur",
        reValidateMode: "onChange",
        defaultValues: {
            ticketCount: 1,
        },
    })

    const currentSlug = watch("slug")

    /**
     * ヘルスチェック、予約できるライブ情報一覧取得hook
     */
    useEffect(() => {
        healthCheckEmailApi().then(getLiveSchedules)
    }, [])

    /**
     * クエリパラメータのライブslugのhook
     */
    useEffect(() => {
        if (
            !queriedTargetSlug ||
            !fetchLivesApiState.response?.contents ||
            fetchLivesApiState.response.contents.length <= 0
        ) {
            return
        }

        const slug = fetchLivesApiState.response.contents.find(
            (content) => content.slug === queriedTargetSlug
        )?.slug

        if (slug) {
            setValue("slug", slug)
        }
    }, [queriedTargetSlug, fetchLivesApiState])

    /**
     * 現在選択されているライブ情報が変更時のhook
     */
    useEffect(() => {
        if (!currentSlug) {
            return
        }
        const isCurrentSlugReservable = reservableLiveList.find(
            (reservableLive) => reservableLive.slug === currentSlug
        )
        setIsReservable(isCurrentSlugReservable !== undefined)

        const currentLive = fetchLivesApiState.response?.contents.find(
            (response) => response.slug === currentSlug
        )
        setCurrentLive(currentLive)

        history.replaceState(
            null,
            document.title,
            createLiveBookingUrl(
                { slug: currentSlug },
                { queryParamOnly: true }
            )
        )
    }, [currentSlug])

    /**
     * 送信ボタンを押した際のハンドリング
     */
    const onSubmitButtonClick = useCallback(() => {
        if (isDisabled) {
            alert("申し訳ございません。現在フォーム利用不可です。")
        }

        if (!isValid) {
            alert("必須項目を満たしてから内容確認を行ってください")
        }
    }, [isValid, isDisabled])

    /**
     * 送信時のハンドリング
     */
    const onSubmit: SubmitHandler<LiveBookingRequest> = useCallback(
        async (data) => {
            if (!confirm("内容を送信します。\nよろしいですか？")) {
                return
            }

            await submit(data, {
                onSuccess: () => {
                    // リダイレクト
                    navigate("/live-booking/send", {
                        state: {
                            isSuccess: true,
                            type: "live-booking",
                            toAddress: data.toAddress,
                        },
                    })
                },
                onFailure: (e) => {
                    if (e.status === 500 || !e.message) {
                        alert(
                            "ただいまメールをお送りすることができません。\n申し訳ございませんが、しばらく時間がたった後再度送信の程よろしくお願いいたします。"
                        )
                    }
                    alert(e.message)
                },
            })
        },
        [isValid]
    )

    /**
     * apiのヘルスチェックを行う
     */
    const healthCheckEmailApi = useCallback(async () => {
        await healthCheck({
            onFailure: () => {
                setIsDisabled(true)
            },
        })
    }, [])

    /**
     * ライブ予定一覧の取得
     */
    const getLiveSchedules = useCallback(async () => {
        await fetchLives({
            onSuccess: (result) => {
                // ライブ情報が存在しない場合はフォームを無効化する
                if (result.total <= 0) {
                    setIsDisabled(true)
                }

                // 予約可能リストの作成
                // live予約一覧APIのレスポンスでreserveUrlが定義されていない場合に、当サイトでチケット予約できるライブとしてreservableSlugListに格納する
                result.contents
                    .filter((c) => c.reserveUrl || c.isSoldOut)
                    .forEach((content) => {
                        setReservableLiveList((reservableLiveList) => [
                            ...reservableLiveList,
                            { ...content },
                        ])
                    })
                setIsDisabled(false)
            },
            onFailure: () => {
                setIsDisabled(true)
            },
        })
    }, [])

    return (
        <GlobalLayout Head={<Head title="チケット予約フォーム" />}>
            {/* ヘルスチェック結果が通らなかった場合に利用できない旨のエラー出力 */}
            {healthCheckApiState.error && (
                <div className="c-notice-page-error">
                    <div
                        className="c-notice-page-error__content"
                        dangerouslySetInnerHTML={{
                            __html: kaigyoToBr(
                                messagesParam.notice.mailApiNotAvailable
                            ),
                        }}
                    />
                </div>
            )}

            <section className="p-section u-mb-100px">
                <h2 className="p-section__title u-mb-50px">
                    チケット予約フォーム
                    <p className="p-section__sub-title">
                        必須項目を記載の上、送信をお願いします
                    </p>
                </h2>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="p-form u-mr-3 u-ml-3 p-section__content"
                >
                    <LiveSelectionForm
                        fetchLivesApiState={fetchLivesApiState}
                        onReloadButtonClick={(e) => {
                            e.preventDefault()
                            getLiveSchedules()
                        }}
                        control={control}
                        errors={errors}
                        defaultValue={queriedTargetSlug || undefined}
                        current={currentLive}
                        className={"mb-14 rsm:text-xs"}
                    />
                    {isReservable ? (
                        <>
                            <CustomSelect
                                name={"ticketCount"}
                                className={"u-width-40 u-mb-10 rsm:text-xs"}
                                control={control}
                                errors={errors}
                                disabled={isDisabled}
                                options={Array.from({ length: 10 }).map(
                                    (_, i) => ({
                                        label: String(i + 1),
                                        value: i + 1,
                                    })
                                )}
                                defaultValue={1}
                            />

                            <CustomInput
                                className="u-mb-10 rsm:text-xs"
                                name={"name"}
                                control={control}
                                errors={errors}
                                disabled={isDisabled}
                            />
                            <CustomInput
                                className="u-mb-10 rsm:text-xs"
                                name={"toAddress"}
                                control={control}
                                errors={errors}
                                disabled={isDisabled}
                            />
                            <CustomInput
                                className="u-mb-10 rsm:text-xs"
                                name={"otherRequest"}
                                control={control}
                                errors={errors}
                                disabled={isDisabled}
                            />

                            <div className="p-form__submit">
                                <BtnKakuLoadAnime
                                    link="#"
                                    type="submit"
                                    isActive={liveBookingApiState.isLoading}
                                    onClick={onSubmitButtonClick}
                                    disabled={isDisabled}
                                >
                                    送信する
                                </BtnKakuLoadAnime>
                            </div>
                        </>
                    ) : (
                        currentLive && <UnReservableForm live={currentLive} />
                    )}
                </form>
            </section>
        </GlobalLayout>
    )
}
